import { DownCaretIcon } from '@components/icons'
import useComponentVisible from '@hooks/useComponentVisible'
import clsx from 'clsx'
import { FC, KeyboardEvent } from 'react'

export interface DropdownProps {
	className?: string
	options: string[]
	setOption?: (option: string) => void | Promise<any>
	currentOption: string
	outline?: boolean
	border?: boolean
	caretStyle?: 'withCircle' | 'default'
}

export const Dropdown: FC<DropdownProps> = ({
	className,
	options,
	setOption,
	currentOption,
	outline,
	border,
	caretStyle = 'default',
}) => {
	const [ref, showOptions, setShowOptions] = useComponentVisible(false)

	const handleOptionSelection = (option): void => {
		setOption(option)
	}

	const onKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
		if (e.key === ' ' || e.key === 'Enter') {
			// Prevents accidental collapsing when toggling buttons within the component
			if (e.target === e.currentTarget) {
				e.preventDefault()
				toggleOptions()
			}
		}
	}

	const toggleOptions = (): void => setShowOptions((show) => !show)

	return (
		<div
			ref={ref}
			onKeyDown={onKeyDown}
			tabIndex={0}
			role="button"
			onClick={toggleOptions}
			className={clsx(
				'rounded-3xl min-w-fit relative px-3',
				border && 'border border-color-3',
				outline && 'focus:outline focus:outline-2 focus:outline-color-8'
			)}
		>
			<div className={clsx(className, 'flex flex-row items-center gap-2 justify-center')}>
				<span className="select-none text-color-6 font-medium text-[18px] capitalize">{currentOption}</span>
				{caretStyle === 'withCircle' ? (
					<span className="shrink-0 flex justify-center items-center w-5 h-5 rounded-full bg-color-2">
						<DownCaretIcon className={clsx(showOptions && 'rotate-180', 'stroke-color-6 w-2 h-2')} />
					</span>
				) : (
					<DownCaretIcon className={clsx(showOptions && 'rotate-180', 'stroke-1 stroke-color-6 w-4 h-4')} />
				)}
			</div>
			{showOptions && (
				<div
					className={clsx(
						'z-[1] bg-color-2 absolute left-0 flex-col gap-5 divide-y divide-color-3 w-full min-w-min rounded-b-2xl',
						outline && 'mt-[3px]'
					)}
				>
					{options.map((option, i) => (
						<button
							className="select-none p-2 hover:bg-color-3 hover:text-color-7 text-color-6 w-full last:rounded-b-2xl capitalize"
							key={i}
							onClick={() => handleOptionSelection(option)}
						>
							<span>{option}</span>
						</button>
					))}
				</div>
			)}
		</div>
	)
}
