import { AirdropEligibility } from '@hooks/query/useAirdropEligibility'
import * as Sentry from '@sentry/nextjs'
import axios from 'axios'
import chunk from 'lodash.chunk'
import { MagicUserMetadata } from 'magic-sdk'
import Qs from 'querystring'
import { QueryFunction } from 'react-query'
import backend from '../apiUtils/backend'
import staticTokens from '../assets/tokens'
import { VestAddressType } from '../hooks/query/useVestsByAddress'
import {
	AirdropUniqueLink,
	Campaign,
	CampaignWithDetails,
	COVALENT__TokenHoldersResult,
	CurrentProfile,
	ENSResolver,
	InfiniteMembershipResponse,
	LeaderBoardConvoUser,
	LeaderBoardUser,
	Membership,
	MembershipsRevenueInfo,
	Nft,
	NftsData,
	NotificationResult,
	Nullable,
	PastAirdrop,
	PoapEventDetails,
	ProfileData,
	Reward,
	RewardWithDetails,
	SendData,
	Token,
	TopSupporterItemWithUser,
	User,
	UserResult,
	Vest,
	_101CourseDetails,
} from '../interfaces/interfaces'
import { DashboardMembershipsResponse } from '../interfaces/memberships.interface'
import { TokenOrStaticToken } from '../pages/airdrop'
import fillTokenDetails from '../utils/fillTokenDetails'
import { isProduction } from './constants'

export const getCurrentUser = async (): Promise<Partial<MagicUserMetadata>> => {
	return axios
		.get('/api/user', { withCredentials: true })
		.then((res) => res.data)
		.catch((e) => {
			if (e?.response?.status !== 401) {
				throw new Error(e)
			} else {
				return
			}
		})
}

export const getCurrentUserProfile = async (): Promise<CurrentProfile> => {
	const { data } = await axios.get<CurrentProfile>('/api/user/details', { withCredentials: true })

	if (window != undefined && data?.user) {
		Sentry.setUser({ wallet_address: data.user.wallet_address })
	}

	return data
}

export const getTwitterUserFollowersCount: QueryFunction<number> = async ({ queryKey }) => {
	const [, twitter_handle] = queryKey
	return axios.get(`api/user/twitter?twitter_handle=${twitter_handle}`).then((res) => res.data.user.followers_count)
}

export const getAirdrop: QueryFunction<AirdropUniqueLink> = async ({ queryKey }) => {
	const [, slug] = queryKey
	return backend.get(`airdrop/${slug}`).then((res) => res.data)
}

export const getAirdropEligibility = async (airdropId: string): Promise<AirdropEligibility> => {
	return await axios.get(`/api/airdrop/verify?id=${airdropId}`, { withCredentials: true }).then((res) => res.data)
}

export const logoutApi = async (): Promise<any> => {
	return axios.post('/api/logout', {}, { withCredentials: true })
}

export const getAllUsers: QueryFunction<User[]> = async () => {
	return backend.get('/user/all').then((res) => res.data)
}

export const searchUser: QueryFunction<User[], SlugQuery> = async ({ queryKey }) => {
	const slug = queryKey[1]
	return backend.get(`/user/search?slug=${slug}`).then((res) => res.data)
}

export const getCampaign: QueryFunction<CampaignWithDetails, SlugQuery> = async ({ queryKey }) => {
	const slug = queryKey[1]

	const campaign = await backend.get(`/campaign/${slug}`).then((res) => res.data)
	const token = await fillTokenDetails(campaign.token)

	return {
		...campaign,
		token,
	}
}

export const getTokensAndStaticTokens: QueryFunction<
	TokenOrStaticToken[],
	[string, Nullable<string> | undefined, Nullable<number>]
> = async ({ queryKey }) => {
	const addr = queryKey[1]
	const network = queryKey[2]
	const searchParams = new URLSearchParams()
	if (addr) searchParams.append('userAddress', addr)
	if (process.env.NODE_ENV === 'production') searchParams.append('production', 'true')
	if (network) searchParams.append('chain', network.toString())

	let tokens: TokenOrStaticToken[]

	const userTokens = await backend.get<Token[]>(`/token?${searchParams.toString()}`).then((res) => res.data)
	if (network)
		tokens = [...userTokens, ...staticTokens.filter((token) => token.network_chain_id === network.toString())]
	else tokens = [...userTokens, ...staticTokens]

	return tokens
}

export const getTokens: QueryFunction<Token[], [string, Nullable<string> | undefined, Nullable<number>]> = async ({
	queryKey,
}) => {
	const addr = queryKey[1]
	const network = queryKey[2]
	const searchParams = new URLSearchParams()
	if (addr) searchParams.append('userAddress', addr)
	// if (process.env.NODE_ENV === 'production') searchParams.append('production', 'true')
	if (network) searchParams.append('chain', network.toString())

	return backend.get<Token[]>(`/token?${searchParams.toString()}`).then((res) => res.data)
}

export const getCampaigns: QueryFunction<Campaign[], NetworkQuery> = async ({ queryKey }) => {
	const network = queryKey[1]
	return axios.get(`/api/campaign?network=${network}`, { withCredentials: true }).then((res) => res.data)
}

export const getReward: QueryFunction<Reward, SlugNetworkQuery> = async ({ queryKey }) => {
	const addr = queryKey[1]
	const network = queryKey[2]
	return backend.get(`/reward?slug=${addr}&chain=${network}`).then((res) => res.data)
}

export const getRewardById: QueryFunction<RewardWithDetails> = async ({ queryKey }) => {
	const id = queryKey[1]
	const reward = await backend.get<RewardWithDetails>(`/reward/${id}`).then((res) => res.data)
	const token = await fillTokenDetails(reward.token)

	return {
		...reward,
		token,
	}
}

export const getRewards: QueryFunction<RewardWithDetails[]> = async () => {
	const searchParams = new URLSearchParams()
	if (isProduction) searchParams.append('production', 'true')
	return backend.get(`/reward?${searchParams.toString()}`).then((res) => res.data)
}

export const getListedNfts: QueryFunction<NftsData, [string, Nullable<number>, Nullable<string>, Nullable<number>]> =
	async ({ pageParam = 0, queryKey }) => {
		const network = queryKey[1]
		const addr = queryKey[2]
		const limit = queryKey[3]

		const url = `/api/nft`

		const searchParams = new URLSearchParams()
		if (network) searchParams.append('chain', network.toString())

		if (pageParam) searchParams.append('cursor', pageParam)

		if (limit) searchParams.append('limit', limit.toString())

		if (addr) {
			searchParams.append('address', addr)
			return axios.get(`${url}?${searchParams.toString()}`).then((res) => res.data)
		} else {
			return axios.get(`${url}?${searchParams.toString()}`).then((res) => res.data)
		}
	}

export const getSends: QueryFunction<SendData, [_key: string, size: string]> = async ({ pageParam = 0, queryKey }) => {
	const size = queryKey[1]
	const url = `/sends`

	const searchParams = new URLSearchParams()
	if (pageParam) {
		searchParams.append('from', pageParam)
	}
	if (size) {
		searchParams.append('size', size)
	}

	if (!isProduction) searchParams.append('allNetworks', 'true')

	return backend.get(`${url}?${searchParams.toString()}`).then((res) => res.data)
}

export const getNft: QueryFunction<Nft, SlugQuery> = async ({ queryKey }) => {
	const id = queryKey[1]
	return axios.get(`/api/nft/${id}`).then((res) => res.data)
}

/* ====================== Get Reputation Score ======================== */
export const getReputaionScore = (addr: string): Promise<any> => {
	return axios
		.get(`https://theconvo.space/api/identity?address=${addr}&apikey=CONVO&_vercel_no_cache=1`)
		.then((res) => res.data)
}

type SlugQuery = [_key: string, slug: string]
type SlugNetworkQuery = [_key: string, slug: string, network?: string, addr?: string, limit?: string]
type NetworkQuery = [_key: string, network: string]

export const getNotifications = (skip?: string): Promise<NotificationResult> =>
	axios
		.get(skip ? `/api/notifications?skip=${skip}` : '/api/notifications', { withCredentials: true })
		.then((res) => res.data)

export const getLeaderboard = async (): Promise<LeaderBoardConvoUser[]> =>
	axios
		.get<LeaderBoardConvoUser[]>('https://theconvo.space/api/custom/lb?apikey=CONVO&limit=5&sortBy=score')
		.then((res) => res.data)

export const getLeaderboardUsers: QueryFunction<LeaderBoardUser[], [string, LeaderBoardConvoUser[]]> = async ({
	queryKey,
}) => {
	const leaderboardConvo = queryKey[1]
	const { users } = await backend
		.get<{ users: User[]; cursor: string }>('/user/all', {
			params: {
				users: leaderboardConvo.map((user) => user._id),
			},
			paramsSerializer: (params) => Qs.stringify(params),
		})
		.then((res) => res.data)

	return leaderboardConvo
		.map((leaderboardUser, i) => {
			const user = users.find((u) => u.wallet_address === leaderboardUser._id)
			if (user) {
				return {
					...leaderboardUser,
					rank: i + 1,
					address: user.wallet_address,
					reputation: leaderboardUser.score,
					user_name: user.user_name,
					profile_pic_url: user.profile_pic_url,
					ens_name: user.ens_name,
					gradient_1: user.gradient_1,
					gradient_2: user.gradient_2,
				}
			} else {
				return null
			}
		})
		.filter(Boolean)
}

export const getProfile: QueryFunction<ProfileData, [string, string]> = async ({ queryKey }) => {
	try {
		const { data } = await backend.get<ProfileData>(`/user?slug=${queryKey[1]}`)
		return data
	} catch (error) {
		if (error?.response?.status === 404) {
			throw new Error('User not found')
		} else {
			Sentry.captureException(error)
			throw error
		}
	}
}

//============================ Get Top Supporters List =============================

export const getTopSupporters: QueryFunction<COVALENT__TokenHoldersResult, [string, string, string]> = ({
	queryKey,
}) => {
	const [, chain_id, address] = queryKey
	return axios
		.get<COVALENT__TokenHoldersResult>(
			`https://api.covalenthq.com/v1/${chain_id}/tokens/${address}/token_holders/?limit=10&key=${process.env.NEXT_PUBLIC_COVALENT_API_KEY}`
		)
		.then((res) => res.data)
}

export const getSupporterList: QueryFunction<TopSupporterItemWithUser[], [string, COVALENT__TokenHoldersResult]> =
	async ({ queryKey }) => {
		const topSupporters = queryKey[1]

		const { users } = await backend
			.get<UserResult>('/user/all', {
				params: {
					users: topSupporters.data.items.map((user) => user.address),
				},
				paramsSerializer: (params) => Qs.stringify(params),
			})
			.then((res) => res.data)

		return topSupporters.data.items.map((supportersUser, i) => {
			const user = users && users.find((u) => u.wallet_address.toLowerCase() === supportersUser.address.toLowerCase())
			return {
				...supportersUser,
				rank: i + 1,
				user,
				percentShare: (parseInt(supportersUser.balance) / parseInt(supportersUser.total_supply)) * 100,
			}
		})
	}

export const getVest: QueryFunction<Vest, [string, string]> = ({ queryKey }) =>
	backend.get<Vest>(`/vest/${queryKey[1]}`).then((res) => res.data)

export const getVestByAddress: QueryFunction<Vest[], [string, VestAddressType, string]> = async ({ queryKey }) => {
	const [, type, address] = queryKey

	const VestType = {
		creator: { creatorAddress: address },
		beneficiary: { beneficiaryAddress: address },
	}

	return backend
		.get<Vest[]>('/vest', {
			params: VestType[type],
		})
		.then((res) => res.data)
}

export const getTokenFromServer = (
	chainId: string | number,
	address: string,
	tokensDistributed = false
): Promise<Token> =>
	backend
		.get(`/token/${chainId?.toString()}/${address}${tokensDistributed ? '?tokensDistributed=true' : ''}`)
		.then((res) => res.data)

export const ensNamesToAddresses = async (
	ensNames: string[]
): Promise<
	{
		address: string
		ensName: string
	}[]
> => {
	const chunkedNames = chunk(ensNames, 100)
	const queries = chunkedNames.map(
		(names) => `{
		domains(where: {name_in: [${names.map((i) => `"${i}"`)}]}) {
			name
			resolvedAddress {
				id
			}
		}
	}`
	)

	try {
		const resolvedAddresses = await Promise.all(
			queries.map((query) =>
				axios
					.post<ENSResolver>('https://api.thegraph.com/subgraphs/name/ensdomains/ens', JSON.stringify({ query }))
					.then((res) => res.data)
			)
		)
		const allResolvedAddresses = resolvedAddresses.flatMap((resolved) => resolved.data.domains)

		return ensNames.map((ensName) => {
			const foundResolved = allResolvedAddresses.find((resolved) => resolved.name === ensName)

			return {
				ensName,
				address: foundResolved?.resolvedAddress?.id ?? null,
			}
		})
	} catch (error) {
		console.log('Ens not resolved', error)
		return null
	}
}

export const getPastAirdrops = (): Promise<PastAirdrop[]> =>
	axios.get('/api/airdrop/all', { withCredentials: true }).then((res) => res.data)

export const getUserQuests: QueryFunction<RewardWithDetails[], [string, string, string]> = ({ queryKey }) =>
	backend
		.get<RewardWithDetails[]>(`/reward?user=${queryKey[1]}&showInactive=${queryKey[2] ? '1' : '0'}`)
		.then((res) => res.data)

export const getPoapEventDetails: QueryFunction<PoapEventDetails, [string, string]> = ({ queryKey }) => {
	const eventId = queryKey[1]

	if (!eventId) throw new Error('Need event id to get poap event details')
	if (!Number.isInteger(Number(eventId))) throw new Error('Event id can only be an integer')

	return axios.get<PoapEventDetails>(`https://api.poap.xyz/events/id/${eventId}`).then((res) => res.data)
}

export const get101BadgeName: QueryFunction<_101CourseDetails> = async ({ queryKey }): Promise<any> => {
	const badgeId = queryKey[1]
	const query = `query course {
		course(id: "${badgeId}") {
		  id
		  title
		  badge {
			id
			onChainId
		  }
		}
	  }
	`
	try {
		if (!badgeId) throw new Error('Need badge id to get 101 course details')
		const res = await axios.post('https://101.xyz/api/graphql', { query })
		if (res.data?.data?.course) {
			return res.data?.data?.course
		} else {
			throw new Error()
		}
	} catch (err) {
		throw new Error('Course does not exist')
	}
}

export const getPendingAirdrops: QueryFunction<AirdropUniqueLink[], [string, string]> = ({ queryKey }) => {
	const chainId = queryKey[1]
	return axios.get(`/api/airdrop/pending?chain=${chainId}`, { withCredentials: true }).then((res) => res.data)
}

export const addressToEns = async (address: string): Promise<string | null> => {
	const query = `{
		domains(where: {resolvedAddress: "${address}"}) {
			name
			resolvedAddress {
				id
			}
		}
	}`

	try {
		const res = await axios.post<ENSResolver>(
			'https://api.thegraph.com/subgraphs/name/ensdomains/ens',
			JSON.stringify({ query })
		)
		return res.data?.data?.domains?.[0]?.name
	} catch (error) {
		console.log('Ens not resolved', error)
		return null
	}
}

export const getMembershipByAddress: QueryFunction<Membership, [string, string | number, string]> = async ({
	queryKey,
}) => {
	const [, chainId, address] = queryKey

	return axios
		.get<Membership>('/api/membership/address', {
			params: { chainId, address },
			withCredentials: true,
		})
		.then((res) => res.data)
}

export const getMembershipUsers: QueryFunction<InfiniteMembershipResponse, [string, string | number, string]> = async ({
	queryKey,
}) => {
	const [, chainId, address] = queryKey

	return axios
		.get<InfiniteMembershipResponse>('/api/membership/users', {
			params: { chainId, address, first: 10, skip: 0 },
			withCredentials: true,
		})
		.then((res) => res.data)
}

export const getUserMemberships: QueryFunction<InfiniteMembershipResponse, [string, string]> = async ({ queryKey }) => {
	const [, address] = queryKey

	return axios
		.get<InfiniteMembershipResponse>('/api/membership/member', {
			params: { address, showTestnet: !isProduction },
			withCredentials: true,
		})
		.then((res) => res.data)
}

export interface UserNFTCollection {
	name: string

	/**
	 * The number of token IDs held by the user
	 */
	amount: number
	tokenAddress: string
	symbol: string
	tokenType: string
}

export const getNFTsByUser = async (chainId: string | number, address: string): Promise<UserNFTCollection[]> => {
	try {
		return backend.get(`/airdrop/getNFTs/${address}/?chainId=${chainId}&tokenType=ERC721`).then((res) => res.data)
	} catch (error) {
		console.log('No NFTs found', error)
		return null
	}
}

export interface ContractNFTItem {
	tokenID: string
	tokenURI: string
	image: string
}
export interface Pagination<T> {
	items: T[]
	cursor: string | null
}
export interface ContractNFTResponse extends Pagination<ContractNFTItem> {
	tokenAddress: string
	tokenType?: string
	name?: string
	symbol?: string
}

export const getNFTsByUserAndContractAddress = async (
	chainId: string | number,
	address: string,
	contractAddress: string
): Promise<ContractNFTResponse> => {
	const url = '/airdrop/getNFTsForContract'

	try {
		return backend.get(`${url}/${address}/${contractAddress}?chainId=${chainId}`).then((res) => res.data)
	} catch (error) {
		console.log('No NFTs found', error)
		return null
	}
}

export const getDashboardMemberships = async (): Promise<DashboardMembershipsResponse['data']> => {
	return axios
		.get<DashboardMembershipsResponse>(`/api/membership/dashboardMemberships`, {
			withCredentials: true,
		})
		.then((res) => res.data.data)
}

export const getMembershipsRevenue = async (): Promise<MembershipsRevenueInfo> => {
	return axios
		.get('/api/membership/revenue', {
			params: {
				showTestnet: !isProduction,
			},
			withCredentials: true,
		})
		.then((res) => res.data)
}
